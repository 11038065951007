import { use } from "react";
import "./lista-ventas.css";
import { useMemo, useState, useRef, useEffect } from "react";

export default function ListaVentas({ ventas }) {
    const gap = 10;
    const [currentTranslate, setCurrentTranslate] = useState(0);
    const [cardWidths, setCardWidths] = useState([]);
    const sliderTrackRef = useRef(null);
    const cardRefs = useRef([]);

    useEffect(() => {
        const widths = cardRefs.current.map((card) => {
            return card.offsetWidth + gap;
        });
        setCardWidths(widths);
    }, [ventas]);

    const totalWidth = cardWidths.reduce((acc, width) => acc + width, 0) - gap;
    const containerWidth = sliderTrackRef.current?.offsetWidth || 0;


    const next = () => {
        const remainingWidth = totalWidth - (currentTranslate + containerWidth);

        if (remainingWidth > 0) {
            let cumulativeWidth = 0;
            let step = 0;

            for (let width of cardWidths) {
                cumulativeWidth += width;
                if (cumulativeWidth > currentTranslate + containerWidth) {
                    step = width;
                    break;
                }
            }

            step = Math.min(step, remainingWidth);
            const newTranslate = currentTranslate + step;


            if (newTranslate + containerWidth > totalWidth || Math.abs(newTranslate + containerWidth - totalWidth) <= 1) {
                setCurrentTranslate(totalWidth - containerWidth + gap);
            } else setCurrentTranslate(newTranslate);
        }
    };

    const prev = () => {
        if (currentTranslate > 0) {
            // Desplazar hacia atrás para mostrar la tarjeta previa
            let cumulativeWidth = 0;
            let step = 0;

            for (let i = cardWidths.length - 1; i >= 0; i--) {
                cumulativeWidth += cardWidths[i];
                if (cumulativeWidth > currentTranslate) {
                    step = cardWidths[i];
                    break;
                }
            }

            step = Math.min(step, currentTranslate); // Limitar al desplazamiento inicial
            setCurrentTranslate((prev) => prev - step);
        }
    };

    return (
        <>
            <div className="lista-ventas">
                <div className="lista-ventas__box">
                    <div className="lista-ventas__botones">
                        <div className="list-ventas__boton">
                            <font>Generar</font>
                            <div className="lista-ventas__boton-icon">
                                <i className="fas fa-plus"></i>
                            </div>
                        </div>
                    </div>
                    <div className="lista-ventas__slide" >
                        <div className="lista-ventas__slide-left"
                            onClick={() => {

                                prev()
                            }}>
                            <i className="fas fa-chevron-left"></i>
                        </div>
                        <div className="lista-ventas__items" ref={sliderTrackRef}>
                            <div className="list-ventas__items-box"

                                style={{ transform: `translateX(-${currentTranslate}px)` }}>
                                {
                                    ventas.map((item, i) => {
                                        return <div key={i} className="lista-ventas__item-card"
                                            ref={(el) => (cardRefs.current[i] = el)}>
                                            <div className="lista-ventas__item-card__header">
                                                <span className="lista-ventas__item-card__header-titulo">Venta</span>
                                                <span className="lista-ventas__item-card__header-subtitulo">#4590</span>
                                            </div>
                                            <div className="lista-ventas__item-card__body">
                                                <span className="lista-ventas__item-card__body-info">6 productos</span>
                                            </div>
                                            <div className="lista-ventas__item-card__footer">
                                                <div className="lista-ventas__item-card__estatus lista-ventas__item-card__estatus--pendiente">
                                                    <div className="lista-ventas__item-card__estatus-text">
                                                        En proceso
                                                    </div>
                                                </div>
                                                <div className="lista-ventas__item-card__button">
                                                    <div className="lista-ventas__item-card__button-text">
                                                        Ver
                                                    </div>
                                                    <div className="lista-ventas__item-card__button-icon">
                                                        <i className="fas fa-chevron-right"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className="lista-ventas__slide-right"
                            onClick={() => {
                                next()
                            }}>
                            <i className="fas fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
