import "./lista-productos.css";
import { ButtonIcon, Chip, LazyImage } from "eureka-design"
import imagen from "../../../imgs/no-cam.png"
export default function ListaProductos({ productos, addConcepto = () => { } }) {
    return <div className="lista-productos">
        <div className="lista-productos__header">
            <div className="lista-productos__filtros">
                <div className="lista-productos__filtros-first">
                    <i className="fas fa-filter"></i>
                    <span>Filtros</span>
                </div>
                <Chip texto="Todos" />
                <Chip texto="Suspension" />
                <Chip texto="Embragues" />
                <Chip texto="Bandas" />
                <Chip texto="Frenos" />
                <Chip texto="Accesorios" />
                <Chip texto="Motor" />
            </div>
        </div>
        <div className="lista-productos__body">
            <div className="lista-productos__body-categorias">

            </div>
            <div className="lista-productos__body-grid">
                {
                    productos.map((producto, i) => {
                        return <div key={i} className="lista-productos__producto">
                            <div className="lista-productos__producto-detalle">
                                <div className="lista-productos__producto-detalle__imagen">
                                    <LazyImage src={imagen} />
                                </div>
                                <div className="lista-productos__producto-detalle__info">
                                    <div className="lista-productos__producto-detalle__titulo">{producto.nombre}</div>
                                    <div className="lista-productos__producto-detalle__detalle">{producto.categoria}</div>
                                    <div className="lista-productos__producto-detalle__detalle">{producto.codigo}</div>
                                </div>
                            </div>
                            <div className="lista-productos__producto-footer">
                                <div className="lista-productos__producto-precio">
                                    <div className="lista-productos__producto-precio__text">
                                        ${producto.precio}
                                    </div>
                                    <span className="lista-productos__producto-precio__detalle">
                                        ({producto.cantidad} disponibles)
                                    </span>
                                </div>
                                <div className="lista-productos__producto-buttons">
                                    <ButtonIcon icono="fa-plus" onClick={
                                        () => addConcepto({
                                            nombre: producto.nombre,
                                            precio: producto.precio,
                                            cantidad: 1,
                                        })
                                    } />
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </div>
}