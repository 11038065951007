import "./venta-proceso.css";
import DesgloseDetalles from "./desglose-detalles";
import MovimientoTipo from "./movimiento-tipo";
import { useState } from "react";

const pantalla = Object.freeze({
    Movimiento: "Movimiento",
    Desglose: "Desglose",
    Metodo: "Metodo",
    Efectivo: "Efectivo",
    Tarjeta: "Tarjeta",
    Terminad: "Terminado",
});

const VentaProceso = ({ conceptos, deleteConcepto = () => { } }) => {
    const [Pantalla, setPantalla] = useState(pantalla.Movimiento);

    return (
        <div className="venta-proceso">
            {Pantalla === pantalla.Movimiento ? <MovimientoTipo onClick={() => setPantalla(pantalla.Desglose)} /> : undefined}
            {Pantalla === pantalla.Desglose ? <DesgloseDetalles
                deleteConcepto={deleteConcepto}
                productos={conceptos}
            /> : undefined}
        </div>
    );
}

export default VentaProceso;